import { waitForElem } from "../utils/waitForElem.js";
import {Urls} from '../constants'

(function () {
    /* global $ */
    "use strict";

    const authSettings = $.sc.authSettings;
	var user_type = '';

	function changeHtmlFontSize(newFontSize) {
		document.documentElement.style.fontSize = newFontSize + 'px';
	}

	// Contrast for East Riding PA Web Direct Provider Registration
	function checkContrastFile() {
		if((window.location.href.indexOf('s4s/CustomPage/Index/202?programId=0fc4dc52-b446-443d-9cb7-b13f00eec3ce') > -1) || (window.location.href.indexOf('s4s/CustomPage/Index/202?programId=1ae56521-c651-408b-adf8-b13100e0d601') > -1)){
			if (localStorage.getItem("contrast") === 'normal') {
				// console.log(localStorage.getItem("contrast"))
				$('.forms-mandatory').each(function() {
					this.style.setProperty('color', '#EB0000', 'important');
				});

				$('.directProviderRegistration .form-navigation-btns__container').each(function() {
					this.style.setProperty('background', '#F1EDEE', 'important');
				});

				$('.directProviderRegistration .form-main__questions-container').each(function() {
					this.style.setProperty('background', '#fff', 'important');
				});

				$('.pcgf-default .form-navigation-btns__container button').each(function() {
					this.style.setProperty('background', '#008485', 'important');
					this.style.setProperty('color', '#fff', 'important');
					this.style.setProperty('border', 'none', 'important');
				});

				$('.directProviderRegistration .form-body h2').each(function() {
					this.style.setProperty('background', '#008485', 'important');
					this.style.setProperty('color', '#fff', 'important');
					this.style.setProperty('border', 'none', 'important');
				});

				$('legend').each(function() {
					this.style.setProperty('color', '#333', 'important');
				});

				$('input').each(function() {
					this.style.setProperty('background', '#fff', 'important');
					this.style.setProperty('color', '#000', 'important');
					this.style.setProperty('border', '1px solid #ccc', 'important');
				});

				$('.pcgf-default .navigation-bar__item__inner-progress').each(function() {
					this.style.setProperty('background', '#fff', 'important');
				});

				$('.pcgf-default .navigation-bar__item__icon-holder i').each(function() {
					this.style.setProperty('border', '3px solid #c4c1b6', 'important');
					this.style.setProperty('background', '#fff', 'important');
				});

				$('.directProviderRegistration .navigation-bar__item_visited .navigation-bar__item__icon-holder i').each(function() {
					this.style.setProperty('background', '#f0f0f0', 'important');
				});

				$('.pcgf-default .navigation-bar__progress-line').each(function() {
					this.style.setProperty('border', '2px solid #ccc', 'important');
					this.style.setProperty('background-color', '#fff', 'important');
				});

				$('.directProviderRegistration .question-type-label p > div > p:first-child').each(function() {
					this.style.setProperty('color', '#fff', 'important');
					this.style.setProperty('background-color', '#170c47', 'important');
				});

				$('.css-1pahdxg-control').each(function() {
					this.style.setProperty('background-color', '', 'important');
				});

				$('.css-1uccc91-singleValue').each(function() {
					this.style.setProperty('color', '', 'important');
				});

				$('.css-yk16xz-control').each(function() {
					this.style.setProperty('border-color', '', 'important');
					this.style.setProperty('background-color', '', 'important');
				});

				$('.pcgf-control__react-select__menu').each(function() {
					this.style.setProperty('color', '', 'important');
					this.style.setProperty('background-color', '', 'important');
				});

				$('.directProviderRegistration .question-type-label p a').each(function() {
					this.style.setProperty('color', '#004a80', 'important');
				});


			} else if (localStorage.getItem("contrast") === 'dark') {
				// console.log(localStorage.getItem("contrast"))
				$('.forms-mandatory').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.directProviderRegistration .form-navigation-btns__container').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.directProviderRegistration .form-main__questions-container').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.pcgf-default .form-navigation-btns__container button').each(function() {
					this.style.setProperty('background', '#000', 'important');
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('border', '2px solid #ff0', 'important');
				});

				$('.directProviderRegistration .form-body h2').each(function() {
					this.style.setProperty('background', '#000', 'important');
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('border', '2px solid #ff0', 'important');
				});

				$('legend').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('input').each(function() {
					this.style.setProperty('background', '#000', 'important');
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('border', '2px solid #ff0', 'important');
				});

				$('.pcgf-default .navigation-bar__item__inner-progress').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.pcgf-default .navigation-bar__item__icon-holder i').each(function() {
					this.style.setProperty('border', '3px solid #ff0', 'important');
					this.style.setProperty('background', '#ff0', 'important');
				});

				$('.directProviderRegistration .navigation-bar__item_visited .navigation-bar__item__icon-holder i').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.pcgf-default .navigation-bar__progress-line').each(function() {
					this.style.setProperty('border', '2px solid #ff0', 'important');
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.directProviderRegistration .question-type-label p > div > p:first-child').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.directProviderRegistration .question-type-label p a').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.css-1pahdxg-control').each(function() {
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.css-1uccc91-singleValue').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.css-yk16xz-control').each(function() {
					this.style.setProperty('border-color', '#ff0', 'important');
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.pcgf-control__react-select__menu').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.pcgf-control__react-select__menu')
				.mouseenter(function() {
					$(this).css({
					'color': '#000',
					'background-color': '#ff0'
					});
				})
				.mouseleave(function() {
					$(this).css({
					'color': '#ff0',
					'background-color': '#000'
					});
				});
				
			}
		}
	
		
	}

	// Contrast for Create Event
	function checkContrastCreateEvent() {
		if(window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/CreateDirectory?templateId=3241e944-9eed-4e14-885a-b13100dc05d8') > -1){
			

			if (localStorage.getItem("contrast") === 'normal') {
				$('.mce-txt').each(function() {
					this.style.setProperty('color', '#000', 'important');
				});

				$('.actionButtons li:first-child a').each(function() {
					this.style.setProperty('color', '#251a52', 'important');
				});

				$('.submitWithChangingAction').each(function() {
					this.style.setProperty('color', '#fff', 'important');
					this.style.setProperty('background', '#251a52', 'important');
				});


				$('.field-validation-error').each(function() {
					this.style.setProperty('background', 'url(/s4s/Themes/Default/images/core/validationBack.jpg) repeat-x top #fff283', 'important');
				});


				$('.inline-tiny-mce').each(function() {
					this.style.setProperty('background', 'transparent', 'important');
				});

				$('.amendAttributeHead').each(function() {
					this.style.setProperty('background', '#ececec', 'important');
					this.style.setProperty('color', '#000', 'important');
				});

				$('.isPublished__container').each(function() {
					this.style.setProperty('border', '2px solid #09093f', 'important');
				});

				$('#Description_ifr').css('background','#fff')
				
				

			} else if (localStorage.getItem("contrast") === 'dark') {
				$('.mce-txt').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.actionButtons li:first-child a').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.submitWithChangingAction').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('background', '#000', 'important');
				});

				$('.field-validation-error').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});


				$('.inline-tiny-mce').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.amendAttributeHead').each(function() {
					this.style.setProperty('background', '#fff', 'important');
					this.style.setProperty('color', '#000', 'important');
				});

				$('.isPublished__container').each(function() {
					this.style.setProperty('border', '2px solid #ff0', 'important');
				});

				$('#Description_ifr').css('background','#fff')

			}
		}
	
		
	}


	function accessibilityCore(){
		// $('.navigation-bar__container').attr('aria-label','Progress bar')

		// Add h1 and remove h2
		if ($('.js-top-header').text().length === 0) {
			let getTitleText = $('.pcgf-default .form-header__title').text();
			$('.js-ppl-registration-form-container').prepend(`<div class="top-header-container"><h1 class="top-header js-top-header">${getTitleText}</h1></div>`)
			$('.pcgf-default .form-header__title').remove()	
		}

		// Hide parent if form-header__action-buttons is empty
		if ($('.form-header__action-buttons').children().length ===0 & $('.form-header__description').text().length === 0) {
			$('.form-header__action-buttons').parent().hide();
	   	}

		// Remove description if empty
		if ($('.form-header__description').text().length === 0) {
			$('.form-header__description').remove();
		}
	}
	
	

    var Attributes = {
        init: function () {
            this.adjustDom();
            this.bindUIActions();
            this.unload();

           // Show work as button
			if(authSettings.isSag === true && authSettings.isWorkAsMode === false){

				$('.js-pcg-access-widget').after(
					`
					<a class="pcg-contrast__link workas-btn" href="/s4s/UserManagement/Users">Work as</a>
					`
				);

			};

			// Show end work as button
			if(authSettings.isWorkAsMode === true || (authSettings.isWorkAsMode === true && authSettings.isOriginalPrincipalSag === true)){

				$('.js-pcg-access-widget').after(
					`
					<a class="pcg-contrast__link workas-btn" href="/s4s/WorkAs/EndWorkAs">Stop working as ${authSettings.name}</a>
					`
				);

			};

			if ( authSettings.isEmployee === true) {
				$('.js-links').append(`<li> <a href="/s4s/CustomPage/Index/${Urls.vacancies_search_er}">Vacancies</a></li>`)
			}

            
        },

        adjustDom: function () {
			
			let contrastCheck = localStorage.getItem("contrast");

			if (contrastCheck === 'normal') {
				$('body').removeClass('contrast');
			} else if (contrastCheck === 'dark') {
				$('body').addClass('contrast');
			}


			let fontCheck = localStorage.getItem("font");

			if (fontCheck === 'normal') {
				changeHtmlFontSize(16); // Change this value to your desired root font size in pixels
			} else if (fontCheck === 'big') {
				changeHtmlFontSize(20); // Change this value to your desired root font size in pixels
			}
            

			// Form styling
			// Create PA listing
			if (window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/CreateDirectory?templateId=4ca7edb3-cf5f-4fa1-a6a3-b13100db1584') > -1) {

                $('body').addClass('createEvent');

            
                setTimeout(function () {

                    $('.isSelectedAll').each(function () {
                        $(`<label for="${$(this).attr('id')}" class="isSelectedAll-label">Select all</label>`).insertAfter($(this));
                    });

                    $('.attributeChildCb').each(function () {
                        $(this).addClass(`${$(this).parent().attr('id')}`)
                    });

                    $('.amendArrow').each(function () {
                        $(this).parent().addClass('amendArrow-attributes');
                    });

                    $('label').each(function () {

                        if ($(this).text() === 'Local authority name:') {
                            $(this).parent().parent().parent().parent().hide();
                        }

                        if ($(this).text() === 'Category:') {
                            $(this).parent().parent().parent().parent().parent().parent().hide();
                        }

                    });

                    $('label[for=Name]').html('PA Username:<span class="required-asterisk">*</span>');

                    $('legend').each(function () {

                        if ($(this).text() === 'Directory image (list):') {
                            $(this).text('Upload profile image')
							// $(this).parent().parent().parent().parent().parent().parent().parent().append('<li style="font-weight: 600; margin-top: 2rem;">Please scroll to the top of the form to save/submit.</li>')
                        }                     

                    })

					$('.js-image-upload-container legend').text('Upload profile image (confirm):')

					$('#mceu_21').prev().text('Additional Information:')

                    $('h2.lhs strong').each(function () {

                        if ($(this).text() === 'Attributes') {
                            $(this).text('Details')
							$(this).addClass('modalDetails')

							$(this).append('<div id="openModalBtnDetails" class="openModalBtn" tabindex="0">'+
							'<div class="icon-circle">'+
								'<i class="fas fa-question"></i>'+
							'</div>'+
							'<h2>What these mean</h2>'+
						'</div>')
                        }

                    });

					$('#openModalBtnDetails').on('click', function(){
						if ($('#modalOverlay').length>0) {
							$('#modalOverlay').css('display','flex')
							$('#modal').css('display','flex')
						} else {
							$('#maincontent').prepend('<div id="modalOverlay" class="overlay-details"></div><div id="modal" class="modal-details" tabindex="0">'+
							'<div class="modal-content" tabindex="0">'+
								'<div class="modal-head">'+
									'<div class="modal-header">'+
										'<div class="icon-circle" >'+
											'<i class="fas fa-question"></i>'+
										'</div>'+
										'<h2 tabindex="0">What these mean</h2>'+
									'</div>'+
									'<button id="closeModalBtn" class="close" aria-label="Close" tabindex="0"><i class="fa fa-times"></i></button>'+
								'</div>'+
								
								'<p><strong tabindex="0">Location</strong></p>'+
								'<p tabindex="0">These are the places PAs are willing to travel for work</p>'+
								'<br>'+
								
								'<p><strong tabindex="0">Requirements</strong></p>'+
								'<p tabindex="0">These are attributes the PA must obtain to be considered</p>'+
								'<br>'+
								
								'<p><strong tabindex="0">CIW</strong></p>'+
								'<p tabindex="0">Care Inspectorate Wales</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">A/L</strong></p>'+
								'<p tabindex="0">Annual leave</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">DBS</strong></p>'+
								'<p tabindex="0">Disclosure and Barring Service</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">HMRC</strong></p>'+
								`<p tabindex="0">His Majesty's Revenue and Customs</p>`+
								'<br>'+
		
								'<p><strong tabindex="0">SCW</strong></p>'+
								'<p tabindex="0">Social Care Wales</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">DP</strong></p>'+
								'<p tabindex="0">Direct Payment Recipient</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">PA</strong></p>'+
								'<p tabindex="0">Personal Assistant</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">Availability needed</strong></p>'+
								'<p tabindex="0">These are the days/times the PA must be available</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">Languages</strong></p>'+
								'<p tabindex="0">These are the languages the PA must speak</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">Provider type</strong></p>'+
								'<p tabindex="0">These are the type of provider the DP is looking for</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">CIC</strong></p>'+
								'<p tabindex="0">Community Integrated Care</p>'+
												
								
								'</div>'+
							'</div>')	
						}	
						
						
						if ($('#closeModalBtn').length>0) {
							$('#closeModalBtn').on('click', function(){
								$('#modalOverlay').css('display','none')
								$('#modal').css('display','none')
							})
						}

						$('.modal-content').focus();
						window.scrollTo(0,0)
					})


					$('body').on('keypress', '#openModalBtnDetails', function (e) {
                        if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
                            $(this).trigger('click'); // Simulate a click event
                        }
                    });

				
					$('body').on('keydown', function(e){
						if (e.which === 27 || e.keyCode === 27) { // Check if Esc key is pressed
							$('#modalOverlay').css('display','none');
							$('#modal').css('display','none');
						}
					});
					
					

                    $('h2').each(function () {

                        if ($(this).text() === 'User') {
                            $(this).html('<p style="font-size: 1rem">Click on the <i class="fa fa-plus-square" aria-hidden="true"></i> icons to expand all options, select the filter options that are relevant to you to help users find you on PA Web.</p>')
                        }

                    });

                    $('.repeating-schedule__label').parent().parent().parent().hide();    

					$('.js-is-published').addClass('publishedSection')
                    $('.js-is-published').prev().addClass('publishedSection')
                    $('.js-is-published').parent().css('display','flex')
                    $('.js-is-published').parent().css('margin-left','10px')
                    $('.js-publish-date').addClass('publishedSection')
                    $('.js-publish-date').prev().addClass('publishedSection')
                    $('.js-publish-date').parent().css('display','flex')
                    $('.js-publish-date').parent().css('margin-left','10px')              

                    $('.amendArrow-right').css('border', 'none').css('margin-right', '10px').html('<i class="fa fa-plus-square" aria-hidden="true" tabindex="0"></i>');
                    //$('.amendAttributeHead').css('margin-left', '20px');


                    //Add or remove + and - icons from 'Lincs'
                    $('body').on('click', '.amendArrow', function (e) {
                        if ($(this).children().hasClass('fa-plus-square')) {
                            $(this).children().removeClass('fa-plus-square');
                            $(this).children().addClass('fa-minus-square');
                        } else if ($(this).next().next().next().html() === "Deselect all") {
                            e.stopPropagation();
                        } else {
                            $(this).children().addClass('fa-plus-square');
                            $(this).children().removeClass('fa-minus-square');
                        }
                    });

                    $('body').on('keypress', '.amendArrow', function (e) {
                        if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
                            $(this).trigger('click'); // Simulate a click event
                        }
                    });

                    //Select and Deselect All 
                    $('body').on('click', '.isSelectedAll', function () {
                        //Add - icon on 'Lincs' when select all checkbox is checked
                        if ($(this).next().html() === "Select all") {
                            if ($(this).prev().prev().children().hasClass('fa-plus-square')) {
                                $(this).prev().prev().children().removeClass('fa-plus-square');
                                $(this).prev().prev().children().addClass('fa-minus-square');
                            }
                        }
                        //Change the text inside of 'Select all' label
                        if ($(this).is(':checked')) {
                            $(this).next().html("Deselect all");
                        } else {
                            $(this).next().html("Select all");
                        }
                    });

                    $('body').on('keypress', '.isSelectedAll', function (e) {
                        if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
                            $(this).trigger('click'); // Simulate a click event
                        }
                    });


                    //Check and uncheck attributes
                    $('body').on('click', '.attributeChildCb', function () {
                        let checkboxesCounter = 0;
                        let idAttribute = $(this).parent().attr('id');
                        let selectAllChecboxTick = $(this).parent().parent().children()[2];
                        let selectAllCheckbox = $(this).parent().parent().children()[3];
                        let checkboxesNumber = document.getElementsByClassName(idAttribute);

                        for (let i = 0; i < checkboxesNumber.length; i++) {
                            if ($(checkboxesNumber[i]).is(':checked')) {
                                checkboxesCounter++;
                                //Check if all the attributes' checkboxes are checked
                                if (checkboxesCounter === checkboxesNumber.length) {
                                    selectAllCheckbox.innerText = "Deselect all";
                                    selectAllChecboxTick.checked = true;
                                } else {
                                    selectAllCheckbox.innerText = "Select all";
                                }
                            }
                        }

                    });

                    $('body').on('keypress', '.attributeChildCb', function (e) {
                        if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
                            $(this).trigger('click'); // Simulate a click event
                        }
                    });

                    $('.js-accordion-body ul').css('display','')


                }, 1000);

				waitForElem(`.js-image-upload-container`).then(() => {
					$('label[for=Name]').html('PA Username:<span class="required-asterisk">*</span>');
					$('#Description').closest('fieldset').children().eq(0).html('About you - Please give some information about the services that you provide and any experience you have:<span class="required-asterisk">*</span')
					$('label[for="Postcode"]').html(`Postcode - this will NOT display on the website:`);
					$('label[for="Email"]').html(`Email - this will NOT display on the website:`);
					$('legend').each(function () {
						if ($(this).text() === 'Title:') {
							$(this).text('Your name - this will NOT display on the website:')
						}   

						if ($(this).text() === 'Gender:') {
							$(this).parent().hide();
						} 
						
						if ($(this).text() === 'Pronouns:') {
							$(this).parent().hide();
							$(this).parent().parent().next().hide();
						}  

						if ($(this).text() === 'If you are self-employed what is your Unique Tax Reference for HMRC:') {
							$(this).text('If you are self-employed what is your Unique Tax Reference for HMRC. This will NOT display on the site. You can upload evidence of your UTR at the bottom of this page.')
						}  

					})
				})

            }

			if(window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/Index') > -1) {

                $('body').addClass('createEvent');

            
                setTimeout(function () {

                    $('.isSelectedAll').each(function () {
                        $(`<label for="${$(this).attr('id')}" class="isSelectedAll-label">Select all</label>`).insertAfter($(this));
                    });

                    $('.attributeChildCb').each(function () {
                        $(this).addClass(`${$(this).parent().attr('id')}`)
                    });

                    $('.amendArrow').each(function () {
                        $(this).parent().addClass('amendArrow-attributes');
                    });

                    $('label').each(function () {

                        if ($(this).text() === 'Local authority name:') {
                            $(this).parent().parent().parent().parent().hide();
                        }

                        if ($(this).text() === 'Category:') {
                            $(this).parent().parent().parent().parent().parent().parent().hide();
                        }

                    });


                    

					$('.js-image-upload-container legend').text('Upload profile image (confirm):')

					

                    $('h2.lhs strong').each(function () {

                        if ($(this).text() === 'Attributes') {
                            $(this).text('Details')
							$(this).addClass('modalDetails')

							$(this).append('<div id="openModalBtnDetails" class="openModalBtn" tabindex="0">'+
							'<div class="icon-circle">'+
								'<i class="fas fa-question"></i>'+
							'</div>'+
							'<h2>What these mean</h2>'+
						'</div>')
                        }

                    });

					$('#openModalBtnDetails').on('click', function(){
						if ($('#modalOverlay').length>0) {
							$('#modalOverlay').css('display','flex')
							$('#modal').css('display','flex')
						} else {
							$('#maincontent').prepend('<div id="modalOverlay" class="overlay-details"></div><div id="modal" class="modal-details" tabindex="0">'+
							'<div class="modal-content" tabindex="0">'+
								'<div class="modal-head">'+
									'<div class="modal-header">'+
										'<div class="icon-circle" >'+
											'<i class="fas fa-question"></i>'+
										'</div>'+
										'<h2 tabindex="0">What these mean</h2>'+
									'</div>'+
									'<button id="closeModalBtn" class="close" aria-label="Close" tabindex="0"><i class="fa fa-times"></i></button>'+
								'</div>'+
								
								'<p><strong tabindex="0">Location</strong></p>'+
								'<p tabindex="0">These are the places PAs are willing to travel for work</p>'+
								'<br>'+
								
								'<p><strong tabindex="0">Requirements</strong></p>'+
								'<p tabindex="0">These are attributes the PA must obtain to be considered</p>'+
								'<br>'+
								
								'<p><strong tabindex="0">CIW</strong></p>'+
								'<p tabindex="0">Care Inspectorate Wales</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">A/L</strong></p>'+
								'<p tabindex="0">Annual leave</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">DBS</strong></p>'+
								'<p tabindex="0">Disclosure and Barring Service</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">HMRC</strong></p>'+
								`<p tabindex="0">His Majesty's Revenue and Customs</p>`+
								'<br>'+
		
								'<p><strong tabindex="0">SCW</strong></p>'+
								'<p tabindex="0">Social Care Wales</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">DP</strong></p>'+
								'<p tabindex="0">Direct Payment Recipient</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">PA</strong></p>'+
								'<p tabindex="0">Personal Assistant</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">Availability needed</strong></p>'+
								'<p tabindex="0">These are the days/times the PA must be available</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">Languages</strong></p>'+
								'<p tabindex="0">These are the languages the PA must speak</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">Provider type</strong></p>'+
								'<p tabindex="0">These are the type of provider the DP is looking for</p>'+
								'<br>'+
		
								'<p><strong tabindex="0">CIC</strong></p>'+
								'<p tabindex="0">Community Integrated Care</p>'+
												
								
								'</div>'+
							'</div>')	
						}	
						
						
						if ($('#closeModalBtn').length>0) {
							$('#closeModalBtn').on('click', function(){
								$('#modalOverlay').css('display','none')
								$('#modal').css('display','none')
							})
						}

						$('.modal-content').focus();
						window.scrollTo(0,0)
					})


					$('body').on('keypress', '#openModalBtnDetails', function (e) {
                        if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
                            $(this).trigger('click'); // Simulate a click event
                        }
                    });

				
					$('body').on('keydown', function(e){
						if (e.which === 27 || e.keyCode === 27) { // Check if Esc key is pressed
							$('#modalOverlay').css('display','none');
							$('#modal').css('display','none');
						}
					});
					
					

                    $('h2').each(function () {

                        if ($(this).text() === 'User') {
                            $(this).html('<p style="font-size: 1rem">Click on the <i class="fa fa-plus-square" aria-hidden="true"></i> icons to expand all options, select the filter options that are relevant to you to help users find you on PA Web.</p>')
                        }

                    });

                    $('.repeating-schedule__label').parent().parent().parent().hide();    

					$('.js-is-published').addClass('publishedSection')
                    $('.js-is-published').prev().addClass('publishedSection')
                    $('.js-is-published').parent().css('display','flex')
                    $('.js-is-published').parent().css('margin-left','10px')
                    $('.js-publish-date').addClass('publishedSection')
                    $('.js-publish-date').prev().addClass('publishedSection')
                    $('.js-publish-date').parent().css('display','flex')
                    $('.js-publish-date').parent().css('margin-left','10px')              

                    $('.amendArrow-right').css('border', 'none').css('margin-right', '10px').html('<i class="fa fa-plus-square" aria-hidden="true" tabindex="0"></i>');
                    //$('.amendAttributeHead').css('margin-left', '20px');


                    //Add or remove + and - icons from 'Lincs'
                    $('body').on('click', '.amendArrow', function (e) {
                        if ($(this).children().hasClass('fa-plus-square')) {
                            $(this).children().removeClass('fa-plus-square');
                            $(this).children().addClass('fa-minus-square');
                        } else if ($(this).next().next().next().html() === "Deselect all") {
                            e.stopPropagation();
                        } else {
                            $(this).children().addClass('fa-plus-square');
                            $(this).children().removeClass('fa-minus-square');
                        }
                    });

                    $('body').on('keypress', '.amendArrow', function (e) {
                        if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
                            $(this).trigger('click'); // Simulate a click event
                        }
                    });

                    //Select and Deselect All 
                    $('body').on('click', '.isSelectedAll', function () {
                        //Add - icon on 'Lincs' when select all checkbox is checked
                        if ($(this).next().html() === "Select all") {
                            if ($(this).prev().prev().children().hasClass('fa-plus-square')) {
                                $(this).prev().prev().children().removeClass('fa-plus-square');
                                $(this).prev().prev().children().addClass('fa-minus-square');
                            }
                        }
                        //Change the text inside of 'Select all' label
                        if ($(this).is(':checked')) {
                            $(this).next().html("Deselect all");
                        } else {
                            $(this).next().html("Select all");
                        }
                    });

                    $('body').on('keypress', '.isSelectedAll', function (e) {
                        if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
                            $(this).trigger('click'); // Simulate a click event
                        }
                    });


                    //Check and uncheck attributes
                    $('body').on('click', '.attributeChildCb', function () {
                        let checkboxesCounter = 0;
                        let idAttribute = $(this).parent().attr('id');
                        let selectAllChecboxTick = $(this).parent().parent().children()[2];
                        let selectAllCheckbox = $(this).parent().parent().children()[3];
                        let checkboxesNumber = document.getElementsByClassName(idAttribute);

                        for (let i = 0; i < checkboxesNumber.length; i++) {
                            if ($(checkboxesNumber[i]).is(':checked')) {
                                checkboxesCounter++;
                                //Check if all the attributes' checkboxes are checked
                                if (checkboxesCounter === checkboxesNumber.length) {
                                    selectAllCheckbox.innerText = "Deselect all";
                                    selectAllChecboxTick.checked = true;
                                } else {
                                    selectAllCheckbox.innerText = "Select all";
                                }
                            }
                        }

                    });

                    $('body').on('keypress', '.attributeChildCb', function (e) {
                        if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
                            $(this).trigger('click'); // Simulate a click event
                        }
                    });

                    $('.js-accordion-body ul').css('display','')


                }, 1000);


				// Suggest a change
				// Retrieve the value of session storage "suggestChange"
				const suggestChangeValue = sessionStorage.getItem("suggestChange");

				// Check if the value exists
				// Suggest a change Vacancy
				if (suggestChangeValue === 'vacancy') {
					waitForElem(`.js-image-upload-container`).then(() => {
						$('label[for="Name"]').html(`Vacancy title - how your vacancy will display on the website:<span class="required-asterisk">*</span>`);
						$('#Description').closest('fieldset').children().eq(0).html('Vacancy description - include some information about the nature of the job and the type of person you are looking for. Think about what a working day will look like for the PA and the types of things they will be doing<span class="required-asterisk">*</span>')
						$('label[for="Postcode"]').html(`Postcode - this will NOT display on the website:`);
						$('label[for="Email"]').html(`Email - this will NOT display on the website:`);
						$('legend').each(function () {
							if ($(this).text() === 'Name of cared for person:') {
								$(this).text('Name of cared for person - this will NOT display on the website:')
							}   

							if ($(this).text() === 'Name of representative (if applicable):') {
								$(this).text('Name of representative (if applicable) - this will NOT display on the website:')
							}  

							// if ($(this).text() === 'Directory image (list):') {
							// 	$(this).text('Upload profile image')
							// 	$(this).parent().parent().parent().parent().parent().parent().parent().append('<li style="font-weight: 600; margin-top: 2rem;">Please click the submit button to send your vacancy to be published.</li>')
							// }  
						})

					})
				// Suggest a change PA	
				} else if (suggestChangeValue === 'pa'){
					waitForElem(`.js-image-upload-container`).then(() => {
						$('label[for=Name]').html('PA Username:<span class="required-asterisk">*</span>');
						$('#Description').closest('fieldset').children().eq(0).html('About you - Please give some information about the services that you provide and any experience you have:<span class="required-asterisk">*</span')
						$('label[for="Postcode"]').html(`Postcode - this will NOT display on the website:`);
						$('label[for="Email"]').html(`Email - this will NOT display on the website:`);
						$('legend').each(function () {
							if ($(this).text() === 'Title:') {
								$(this).text('Your name - this will NOT display on the website:')
							}   

							if ($(this).text() === 'Gender:') {
								$(this).parent().hide();
							} 
							
							if ($(this).text() === 'Pronouns:') {
								$(this).parent().hide();
								$(this).parent().parent().next().hide();
							}  

							if ($(this).text() === 'If you are self-employed what is your Unique Tax Reference for HMRC:') {
								$(this).text('If you are self-employed what is your Unique Tax Reference for HMRC. This will NOT display on the site. You can upload evidence of your UTR at the bottom of this page.')
							}  

							// if ($(this).text() === 'Directory image (list):') {
							// 	$(this).text('Upload profile image')
							// 	$(this).parent().parent().parent().parent().parent().parent().parent().append('<li style="font-weight: 600; margin-top: 2rem;">Please scroll to the top of the form to save/submit.</li>')
							// }  

						})
					})
				}

            }

			if (window.location.href.indexOf('/s4s/EventAdministrationDetails/EventDetails') > -1) {
				
				$('.js-image-upload-container').parent().parent().prepend('<li style="font-weight: bold; font-size: 16px ">Please upload your image in both fields.</li>')
			}

			
			if ((window.location.href.indexOf('/s4s/signup/personaldetails') > -1) || (window.location.href.indexOf('/s4s/signUp/PersonalDetails') > -1) || (window.location.href.indexOf('/s4s/SignUp/PersonalDetails') > -1)){
				$('#myLifeExcerpt').hide();


				waitForElem(`.js-password-container`).then(() => {
                    $("label[for='OrganisationIdString']").parent().hide();
                    $('#PreferableContactTypeStr_Email').closest('fieldset').parent().hide();
					$('#DateOfBirthBlock').next().hide();
					$('.js-mobile-phone-container').parent().hide();
					$('.address-search__container').parent().parent().parent().parent().hide();
					$('.js-user-type-container').hide();
                });

				if ('.validation-summary-errors') {
					waitForElem(`.validation-summary-errors`).then(() => {
						setTimeout(() => {
							$('.validation-summary-errors').attr('tabindex', '0').focus();
						}, 500);
					})
				}

				$('.js-next-step-button').on('click',function(){
					waitForElem(`.validation-summary-errors`).then(() => {
						setTimeout(() => {
							$('.validation-summary-errors').attr('tabindex', '0').focus();
						}, 500);
					})
				})

				waitForElem(`#PreferableContactTypeStr_Email`).then(() => {
					$('#PreferableContactTypeStr_Email').closest('fieldset').parent().next().hide();
					$('#PreferableContactTypeStr_Email').closest('fieldset').parent().hide();
					$('#PrincipalTypes_0__Id').parent().hide();
					$('#PrincipalTypes_2__Id').parent().hide();
				});
				
			}

			if (window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/Index') > -1) {
				$('title').text('Suggest a change');
 
				 setTimeout(function () {
					 $('.actionButtons').before(
						 '<ul class="account intro-title-section" style="margin-bottom: 2rem; margin-top: 1rem;">' +
 
							 '<h1 tabindex="0" style="font-size: 2rem">Suggest a change</h1>' +
 
						 '</ul>'
					 )

					 $('legend').each(function () {
						if ($(this).text() === 'Hourly rate:') {
							$(this).parent().addClass('hourly-fieldset')
							$(this).parent().parent().css('padding-left','0')
							$('.js-inline-tiny-mce').addClass('hourly-input')
						}   
 
					})

					$('#IsPublished').prev().text('Tick to request profile published on site:')
				}, '500')
			}

			 if (window.location.href.indexOf('/s4s/MyProfile/MyProfileTab') > -1) {
				setTimeout(function () {
					$('body').addClass('myProfileTab');  
					$('label[for="UserPicUploadImage"]').text('Upload your logo or image. Please note files must not exceed 20Kb and must be in JPEG format.');
					$('label[for="UserPicUploadImage"]').css({
						'display': 'block',
						'width': '100%',
						'margin-bottom': '1rem'
					  });
			   	}, '500')
			 }


			 if ((window.location.href.indexOf('/s4s/signup/personaldetails') > -1) || (window.location.href.indexOf('/s4s/signUp/PersonalDetails') > -1) || (window.location.href.indexOf('/s4s/SignUp/PersonalDetails') > -1)){
				setTimeout(function () {
					$('body').addClass('personalDetails');  
			   	}, '500')
				   	
			 }
			 	


			if (window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/CreateDirectory?templateId=4ca7edb3-cf5f-4fa1-a6a3-b13100db1584') > -1) {

                setTimeout(function () {
                    $('.actionButtons').before(
                        '<ul class="account intro-title-section" style="margin-bottom: 2rem; margin-top: 1rem;">' +

                            '<h1 tabindex="0" style="font-size: 2rem; margin-bottom: 1rem;">Create event</h1>' +
							'<p style="max-width: 760px;">If you would like to save and come back to this form later, click submit. Only tick the ‘send to publish’ box when you are ready to publish your listing.</p>' +

                        '</ul>'
                   	)

					$('legend').each(function () {
						if ($(this).text() === 'Hourly rate:') {
							$(this).parent().addClass('hourly-fieldset')
							$(this).parent().parent().css('padding-left','0')
							$('.js-inline-tiny-mce').addClass('hourly-input')
						}                     
					})

					$('#EventListImageUpload_ImageUploaded').parent().parent().parent().prepend('<li style="padding-left: 15px;color: #000;font-size: 1rem;	font-weight: 600; line-height: 1.5rem;">Upload your logo or image. Please note files must not exceed 20Kb and must be in JPEG format.</li>')
					$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().addClass('mar-left1')
					$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().addClass('mar-right1')
					$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().css('padding-left','0px')

				}, '1000')
			}


			// Create a vacancy
			if (window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/CreateDirectory?templateId=3241e944-9eed-4e14-885a-b13100dc05d8') > -1) {
				waitForElem(`.js-image-upload-container`).then(() => {
					$('label[for="Name"]').html(`Vacancy title - how your vacancy will display on the website:<span class="required-asterisk">*</span>`);
					$('#Description').closest('fieldset').children().eq(0).html('Vacancy description - include some information about the nature of the job and the type of person you are looking for. Think about what a working day will look like for the PA and the types of things they will be doing<span class="required-asterisk">*</span>')
					$('label[for="Postcode"]').html(`Postcode - this will NOT display on the website:`);
					$('label[for="Email"]').html(`Email - this will NOT display on the website:`);
					$('legend').each(function () {

                        if ($(this).text() === 'Directory image (list):') {
                            $(this).text('Upload profile image')
							$(this).parent().parent().parent().parent().parent().parent().parent().append('<li style="font-weight: 600; margin-top: 2rem;">Please click the submit button to send your vacancy to be published.</li>')
                        }  
						
						if ($(this).text() === 'Name of cared for person:') {
							$(this).text('Name of cared for person - this will NOT display on the website:')
						}   

						if ($(this).text() === 'Name of representative (if applicable):') {
							$(this).text('Name of representative (if applicable) - this will NOT display on the website:')
						}  

                    })

					

					$('.js-image-upload-container legend').text('Upload profile image (confirm):')
					$('.js-image-upload-container legend').eq(0).text('Upload profile image:')

					$('.js-choose-file').each(function() {
						this.style.setProperty('box-sizing', 'border-box');
					});

				})

				const mutationObserver = new MutationObserver(entries =>{
					checkContrastCreateEvent();
				})
				const parent = document.body
				mutationObserver.observe(parent,{
					subtree: true,
					attributes: true,
				})

			}


			// AUTH Login / Sign Up //
			if (window.location.href.indexOf('/s4s/Auth?ReturnUrl=/s4s/CustomPage/Index') > -1) {
				// Add class to page
				// console.log('auth')
				$('body').addClass('auth-page');			
			}


			// Add accessibility script
			if((window.location.href.indexOf('s4s/CustomPage/Index/202?programId=0fc4dc52-b446-443d-9cb7-b13f00eec3ce') > -1) || (window.location.href.indexOf('s4s/CustomPage/Index/202?programId=1ae56521-c651-408b-adf8-b13100e0d601') > -1)){
				
				$('body').addClass('directProviderRegistration');    

				waitForElem(`.form-header__description`).then(() => {

					// Add h1 and remove h2
					if ($('.js-top-header').text().length === 0) {
						let getTitleText = $('.pcgf-default .form-header__title').text();
						$('.js-ppl-registration-form-container').prepend(`<div class="top-header-container"><h1 class="top-header js-top-header">${getTitleText}</h1></div>`)
						$('.pcgf-default .form-header__title').remove()	
					}

					// Add breadcrumbs
					$('.top-header-container').prepend(`<div class="cm-template-body-location" role="navigation" aria-label="You are here:"><span><a href="/s4s/CustomPage/Index/${Urls.homepage_er}">Home <i class="fas fa-angle-right"></i></a><span class="cm-template-body-location-content">PA Registration</span></span></div>`)

					// Check if Header description is empty an delete it
					if ($('.form-header__description').text().length === 0) {
						$('.form-header__description').remove();
					}  

					// Hide parent if form-header__action-buttons is empty
					if ($('.form-header__action-buttons').children().length ===0 & $('.form-header__description').text().length === 0) {
						$('.form-header__action-buttons').parent().hide();
					}
					
				})			
			} 


			if( window.location.href.indexOf('/s4s/EventAdministrationDetails') > -1 
			|| window.location.href.indexOf('/s4s/EventWizard/AdditionalInformation') > -1 ) {
				$('body').addClass('PPL-events-details');

				waitForElem(`.amendArrow`).then(() => {
					$('.amendArrow').closest('.col').addClass('attribute-box');
					$('.attribute-box').parent().parent().addClass('attribute-box__container');

					$('.js-image-upload-container').closest('.blocks').addClass('directory-settings__container');
					$('#IsPublished').parent().addClass('isPublished__container');
					
					$('strong').each(function () {
						if ($(this).text() === 'Attributes') {
	
							$(this).parent().next().next().children().eq(0).replaceWith(`<p>Select relevant options from the list below to tag your listing and help users find your service. Use the arrow icons to expand the options</p>`)
							//Click on the  icons to expand all options, select the filter options that are relevant to you to help users find you on PA Web.


						}
					});
	

					$('.isPublished__container label').text('Tick to publish')
				});

				

				// waitForElem(`sc-event-documents`).then(() => {
				// 	$('sc-event-documents').parent().prepend(
				// 		`
				// 			<p><strong>Please upload:</strong></p>
				// 			<ol class="document-helper">
				// 				<li>At least x2 Provider Case Studies where you have delivered the services in market for UK companies.</li>
				// 				<li>a copy of any Insurance Policies that are in place and being maintained in line with the Provider Terms & Conditions.</li>
				// 				<li>A copy of annual audited accounts for the preceding x2 years showing a positive balance sheet. If you are a sole trader or an enterprise, a statement on the company letterhead signed by one of the directors confirming you have had a positive balance sheet for the past x2 years.</li>
				// 			</ol>
				// 		`
				// 	)
				// });

				// let currentDirectoryText = $('#administrationExcerpt').find('label').text();
				// currentDirectoryText = currentDirectoryText.replace('directory', 'listing')
				// $('#administrationExcerpt').find('label').text(currentDirectoryText);

				$('#categoryNameText').closest('.account').hide();
				$('.repeating-schedule').closest('.ValidateAltNorm').hide();

				//$('label[for="Name"]').html(`Listing name:<span class="required-asterisk">*</span>`);

				// $('label').each(function(){

				// 	var label = $(this);
				// 	var labelText = label.text();

				// 	// Replace 'Local authority' with 'System'
				// 	var updatedText = labelText.replace('Local authority', 'System');
				// 	label.text(updatedText);
				// })

			}


			if(window.location.href.indexOf('/s4s/EventWizard/AdditionalInformation') > -1 ) {
				waitForElem(`.js-image-upload-container`).then(() => {
					$('label[for=Name]').html('PA Username:<span class="required-asterisk">*</span>');
					$('#Description').closest('fieldset').children().eq(0).html('About you - Please give some information about the services that you provide and any experience you have:<span class="required-asterisk">*</span')
					$('label[for="Postcode"]').html(`Postcode - this will NOT display on the website:`);
					$('label[for="Email"]').html(`Email - this will NOT display on the website:`);
					$('legend').each(function () {
						if ($(this).text() === 'Title:') {
							$(this).text('Your name - this will NOT display on the website:')
						}   

						if ($(this).text() === 'Gender:') {
							$(this).parent().hide();
						} 
						
						if ($(this).text() === 'Pronouns:') {
							$(this).parent().hide();
							$(this).parent().parent().next().hide();
						}  

						if ($(this).text() === 'If you are self-employed what is your Unique Tax Reference for HMRC:') {
							$(this).text('If you are self-employed what is your Unique Tax Reference for HMRC. This will NOT display on the site. You can upload evidence of your UTR at the bottom of this page.')
						}  

					})



					$('label[for="AllowedSPIds"]').parent().hide();
					$('label[for="AllowedLAIds"]').parent().hide();
					$('label[for="EventOwnerIds"]').parent().hide();


				})
			}


			if (window.location.href.indexOf('/s4s/CustomPage') === -1) {
				$('body').addClass('non-custom-page');
			} 
			
			
    
        },

        bindUIActions: function () {
            // Bind actions

		
			const skipContentBtn = document.querySelector('.js-skip-content');
			if (skipContentBtn !== null ) {
				skipContentBtn.addEventListener('click', (e)=> {
					e.preventDefault();
					$([document.documentElement, document.body]).animate({
						scrollTop: $('.cms-content').offset().top
					}, 500);
	
					$('#myNavbar a, #myNavbar li').attr('tabindex' , '2');
					$('.cms-content a, .cms-content input, .cms-content select, .cms-content textarea, .js-skip-content, .footer-container a').attr('tabindex' , '1');
				});
			}


			$('body').on('click', '.js-accessibility-widget__font-size', function(e) {
				e.preventDefault();
				var fontSize = $(this).data('font-size');
				localStorage.setItem('font', fontSize === '16px' ? 'normal' : 'big');
				changeHtmlFontSize(fontSize);
				location.reload();
			});

			$('body').on('click', '.js-accessibility-widget__contrast', function(e) {
				e.preventDefault();
				var contrastMode = $(this).data('contrast');
				localStorage.setItem('contrast', contrastMode);
				if (contrastMode === 'normal') {
					$('body').removeClass('contrast');
				} else if (contrastMode === 'dark') {
					$('body').addClass('contrast');
				}
				checkContrastFile();
				checkContrastCreateEvent();
			});

			if((window.location.href.indexOf('s4s/CustomPage/Index/202?programId=0fc4dc52-b446-443d-9cb7-b13f00eec3ce') > -1) || (window.location.href.indexOf('s4s/CustomPage/Index/202?programId=1ae56521-c651-408b-adf8-b13100e0d601') > -1)){

				setTimeout(function() {
					checkContrastFile()
				}, 500); 
				
					
				$('body').click(function() {
					checkContrastFile()
				})  
			
			} 


			// CM Template Pages
			const sidebarNavItems = $('.js-sidebar-nav__item');
			const prevButton = $('.js-prev');
			const nextButton = $('.js-next');
	
			// Initialize the pagination buttons
			updatePaginationButtons();
	
			sidebarNavItems.click(function() {
				sidebarNavItems.removeClass('active-link');
				$(this).addClass('active-link');
	
				// Update pagination buttons
				updatePaginationButtons();
			});
	
			prevButton.click(function(event) {
				// event.preventDefault();
				const activeLink = $('.js-sidebar-nav__item.active-link');
				if (activeLink.prev().length) {
					activeLink.removeClass('active-link');
					activeLink.prev().addClass('active-link');
					//  updatePaginationButtons();
				}
			});
	
			nextButton.click(function(event) {
				// event.preventDefault();
				const activeLink = $('.js-sidebar-nav__item.active-link');
				if (activeLink.next().length) {
					activeLink.removeClass('active-link');
					activeLink.next().addClass('active-link');
					updatePaginationButtons();
				}
			});
	
			function updatePaginationButtons() {
				// Check if the current URL matches any URL in the sidebar navigation and add 'active-link' class
				const currentUrl = window.location.href;
				const currentUrlSplited = '/' + currentUrl.split('/').slice(3).join('/');
				sidebarNavItems.each(function() {
					const navUrl = $(this).find('a').attr('href');
					if (navUrl === currentUrlSplited) {
						$(this).addClass('active-link');
					}
				});
				const activeLink = $('.js-sidebar-nav__item.active-link');
				const prevUrl = activeLink.prev().find('a').attr('href');
				const nextUrl = activeLink.next().find('a').attr('href');

				// Update href attributes of prev and next buttons
				prevButton.attr('href', prevUrl);
				nextButton.attr('href', nextUrl);

				// Show/hide pagination buttons based on active link position
				if (activeLink.is(':first-child')) {
					prevButton.addClass('hidden');
				} else {
					prevButton.removeClass('hidden');
				}

				if (activeLink.is(':last-child')) {
					nextButton.addClass('hidden');
				} else {
					nextButton.removeClass('hidden');
				}
			}

			
			
	
			// Accordion
			$('.js-accordion').on('click keypress', function (event) {
				// Check if the event type is 'click' or 'keypress' and if the key pressed is Enter (key code 13)
				if (event.type === 'click' || (event.type === 'keypress' && event.which === 13)) {
					// Toggle the class 'active-accordion' on the clicked element
					$(this).toggleClass('active-accordion');
	
					// Toggle aria-expanded attribute
					$(this).attr('aria-expanded', function (_, attr) {
						return attr === 'true' ? 'false' : 'true';
					});
	
					// Find the parent of the clicked element, then find its '.arrow' element and toggle the class 'arrow-animate'
					$(this).find('.arrow').toggleClass('arrow-animate');
	
					// Find the parent of the clicked element, then find its '.content' element and slide toggle its visibility over 280 milliseconds
					$(this).find('.content').slideToggle(280);
				}
			});

			// Content pages
			if ($('.js-accordion').attr('aria-expanded','true')) {
				$('.js-accordion').click();
			}
 
			

        },

        unload: function () {},
    };

    Attributes.init();
})();